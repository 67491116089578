div#c-uploads {
  div#a-show {
    div#upload-guide-notice {
      margin-bottom: 2em;
    }

    div#related-posts-by-source, div#iqdb-similar {
      display: inline-block;
    }

    div.upload_rating .radio label {
      font-weight: normal;
      display: inline;
      margin-right: 2em;

      input {
        margin-right: 0.5em;
      }
    }

    div.field_with_errors {
      display: inline;
    }

    #related-tags-container {
      margin-top: 1em;
    }
  }
}
