div.prose {
  line-height: 1.4em;
  word-break: break-word;

  > *:last-child {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0.75rem;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
    margin-bottom: 0.75rem;
  }

  h1, h2, h3, h4 {
    font-size: var(--text-xl);

    &:not(:first-child) {
      margin-top: 1.75rem;
    }
  }

  h5 {
    font-size: var(--text-lg);
  }

  h6 {
    font-size: var(--text-md);
  }

  table {
    th {
      white-space: nowrap;
    }
  }

  ul {
    margin-left: 1em;
    margin-bottom: 0.75rem;

    ul {
      margin-bottom: 0;
    }
  }

  li {
    list-style-type: disc;
  }

  code, pre {
    font: var(--monospace-font);
    background: var(--dtext-code-background);
  }

  pre {
    margin: 0.5em 0;
    padding: 0.5em 1em;
    white-space: pre-wrap;
  }

  blockquote {
    margin-bottom: 1em;
    padding-left: 0.5em;
    opacity: 0.75;
    border-left: 3px solid var(--dtext-blockquote-border-color);

    blockquote {
      opacity: 1;
    }
  }

  .tn {
    font-size: 0.8em;
    color: var(--muted-text-color);
  }

  div.expandable {
    margin-bottom: 1em;
    border: var(--dtext-expand-border);
  }

  div.expandable-header {
    padding: 0.4em;

    span {
      margin-right: 0.5em;
      font-weight: bold;
    }
  }

  div.expandable-content {
    display: none;
    padding: 0.4em;

    > :last-child {
      margin-bottom: 0;
    }
  }

  a.dtext-named-external-link::after {
    // https://stackoverflow.com/a/66093928
    content: "";
    padding: 0 0.275em;
    margin: 0 0.25rem;
    vertical-align: -0.125em;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z'%3E%3C/path%3E%3C/svg%3E");
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: currentcolor;
  }

  a.dtext-wiki-does-not-exist, a.dtext-tag-does-not-exist, a.dtext-artist-does-not-exist {
    text-decoration: dotted underline;
  }

  .spoiler:not(:hover), .spoiler:not(:hover) * {
    color: transparent !important;
  }

  .spoiler:hover {
    color: var(--dtext-spoiler-hover-color);
  }

  // Prevent accidentally opening a link when tapping a spoiler to reveal it
  .spoiler:hover a {
    animation: delay-pointer-events 50ms;
  }

  @keyframes delay-pointer-events {
    0%, 99% {
      pointer-events: none;
    }
  }

  .spoiler {
    background: var(--dtext-spoiler-background-color);
  }

  details {
    margin-bottom: 1em;

    summary {
      margin-bottom: 1em;
    }
  }
}

div.dtext-preview {
  display: none;

  ul {
    margin-left: 1em;
  }
}

div#dtext-help {
  td {
    > *, div.spoiler p {
      margin: 0;
    }

    h1, h6 {
      padding: 0;
    }
  }

  #dtext-help-footnote {
    margin: 1em 0 0 0;
  }
}
